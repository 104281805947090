import { Fragment, useEffect, useState } from "react";
import { hotjar } from "react-hotjar";
import * as ga from "../helper/lib/googleAnalytics";
import { useRouter } from "next/router";
import { getCookie, setCookie } from "../helper/lib/cookie";
import { Container, GlobalStyle } from "../styled/GlobalStyle";
import { ThemeProvider } from "styled-components";
import StyledComponentsRegistry from "../lib/registry";
import { TourProvider } from "../context/TourContext/TourContext";

function MyApp({ Component, pageProps, previousTheme }) {
  const [mode, setMode] = useState(
    previousTheme === "" ? "light" : previousTheme
  );
  const router = useRouter();

  const toggleTheme = () => {
    if (mode === "light") {
      setCookie("theme", "dark");
      return setMode("dark");
    }
    setCookie("theme", "light");
    return setMode("light");
  };

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageView(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      hotjar.initialize(process.env.NEXT_PUBLIC_HOTJAR_ID, 6);
    }
  }, []);

  return (
    <Fragment>
      {/* <StyledComponentsRegistry> */}
      <ThemeProvider theme={{ mode }}>
        <GlobalStyle />
        <Container>
          <TourProvider>
            <Component {...pageProps} toggleTheme={toggleTheme} mode={mode} />
          </TourProvider>
        </Container>
      </ThemeProvider>
      {/* </StyledComponentsRegistry> */}
    </Fragment>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {};
  let previousTheme = null;
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  if (ctx.req) {
    previousTheme = await getCookie("theme", ctx.req.headers.cookie);
  }
  return {
    pageProps,
    previousTheme,
  };
};

export default MyApp;
