import React, { createContext, useState, useContext, useEffect } from "react";
import nookies from "nookies";

// Create a context for the tour
const TourContext = createContext();

export const TourProvider = ({ children }) => {
  const [isClosedAll, setIsClosedAll] = useState(false);

  // Check for any stored cookies about overlays being closed
  useEffect(() => {
    const { tourClosed } = nookies.get(null);
    if (tourClosed === "true") {
      setIsClosedAll((prev) => ({ ...prev }));
    }
  }, []);

  const onCloseAllTours = () => {
    setIsClosedAll(true); // Correctly set the boolean value
    nookies.set(null, "tourClosed", "true", { path: "/" });
  };

  return (
    <TourContext.Provider value={{ isClosedAll, onCloseAllTours }}>
      {children}
    </TourContext.Provider>
  );
};

// Custom hook to use the tour context
export const useTour = () => {
  return useContext(TourContext);
};
