const Cookies = require('js-cookie');

module.exports = {
  getCookie: async (cookieName, cookieString) => {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(cookieString);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
  setCookie: (cookieName, cookieValue) => {
    Cookies.set(cookieName, cookieValue, {expires: 365});
  }
};