import styled, { createGlobalStyle, keyframes } from "styled-components";
import theme from "styled-theming";
import { themes } from "./theme";

import { slideInUp, fadeIn, fadeInUp } from "react-animations";

const slideInUpAnimation = keyframes`${slideInUp}`;

const fadeInAnimation = keyframes`${fadeIn}`;

export const boxBackgroundColor = theme("mode", {
  light: themes.light.background,
  dark: themes.dark.background,
});

export const color = theme("mode", {
  light: themes.light.color,
  dark: themes.dark.color,
});

export const wrap = theme("mode", {
  light: themes.light.container,
  dark: themes.dark.container,
});

export const border = theme("mode", {
  light: themes.light.border,
  dark: themes.dark.border,
});

export const shadow = theme("mode", {
  light: themes.light.shadow,
  dark: themes.dark.shadow,
});

export const bigShadow = theme("mode", {
  light: themes.light,
  dark: themes.bigShadow,
});

export const padding = `
  30px 30px;
`;

export const AnimatedContainer = styled.div`
  animation: 250ms ${slideInUpAnimation} ease-in-out;
`;

export const AnimatedFadeInContainer = styled.div`
  animation: 1s ${fadeInAnimation};
`;

export const Container = styled.div`
  position: relative;
  background-color: ${boxBackgroundColor};
  transition: all 250ms ease-out 0s !important;
`;

export const GridContainer = styled.div`
  max-width: 1200px;
  position: relative;
  margin: -16px auto 0px;
  padding-bottom: 80px;
`;

export const GlobalStyle = createGlobalStyle`
  body, html {
    background-color: ${boxBackgroundColor};
    padding: 0;
    margin: 0;
  }

  * {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }



  @font-face {
    font-family: 'Moranga Bold';
    src: local('Moranga Bold'), local('MorangaBold'),
    url('/fonts/Moranga-Bold.woff') format('woff2'),
    url('/fonts/Moranga-Bold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  p{
    font-family: "Quicksand", system-ui;
    margin:0px;
  }

  button {
    font-family: "Quicksand", system-ui;
  }

  h2{
    font-family: "Moranga Bold", system-ui;
    font-size:42px;
    margin:0px;
  }

  h3{
    font-family: "Moranga Bold", system-ui;
    font-size:20px;
    margin:0px;

  }


  .react-grid-item {
    box-sizing: border-box;
    transition: all 500ms ease 0s !important;
    will-change: transform;
    overflow: hidden;
  }

  .react-grid-item.resizing {
    opacity: 0.9;
  }

  .react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
  }

  .react-grid-item .minMax {
    font-size: 12px;
  }

  .react-grid-item .add {
    cursor: pointer;
  }

  .react-grid-dragHandleExample {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    cursor: pointer;

  }

  .toolbox {
    background-color: #dfd;
    width: 100%;
    height: 120px;
    overflow: scroll;
  }

  .hide-button {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 0px;
    right: 5px;
  }

  .toolbox__title {
    font-size: 24px;
    margin-bottom: 5px;
  }

  .toolbox__items {
    display: block;
  }

  .toolbox__items__item {
    border-radius: 32px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
    background-color: #ddd;
  }

  .droppable-element {
    border-radius: 32px;
    width: 150px;
    text-align: center;
    background: #fdd;
    border: 1px solid black;
    margin: 10px 0;
    padding: 10px;
  }

  .react-grid-layout {
    //cursor: grab;
    cursor: pointer;
    position: relative;
    transition: height 200ms ease;
  }

  .react-grid-item {
    transition: all 50ms ease;
    transition-property: left, top;
  }

  .react-grid-item img {
    pointer-events: none;
    user-select: none;
  }

  .react-grid-item.cssTransforms {
    transition-property: transform;
  }

  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    transition: none !important;
    z-index: 3;
    will-change: transform !important;

    &:active {
      cursor: grabbing;
    }
  }

  .react-grid-item.dropping {
    visibility: hidden;
  }

  .react-grid-item.react-grid-placeholder {
    background: black;
    border-radius: 32px;
    opacity: 0.2;
    transition-duration: 50ms;
    z-index: 2;
    user-select: none;
  }
`;
