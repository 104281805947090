export const themes = {
  light: {
    color: "#0d1117",
    background: "#f7f2f2",
    container: "#ffffff",
    shadow: "transparent 0px 0px 0px 2px inset",
    border: "rgb(240, 242, 248)",
    whiteShadow: "rgb(240 242 248) 0px 0px 0px 2px",
    transWhite: "rgb(240 242 248 / 20%) 0px 0px 0px 2px",
  },
  dark: {
    color: "#f0f2f8",
    background: "#090c10",
    container: "#0d1117",
    shadow: "rgb(48 54 61) 0px 0px 0px 2px inset",
    border: "rgb(48, 54, 61)",
  },
  bigShadow: "rgb(48 54 61) 0px 0px 0px 5px",
  colors: {
    cyan: "rgb(152, 208, 255)",
    white: "#fff",
    solitude: "rgb(240 242 248 / 27%)",
    shark: "rgb(48, 54, 61)",
    grey: "rgb(237, 233, 233)",
  },
  fonts: {
    sm: "",
    base: 14,
    md: "",
    lg: 16,
  },
  border: {
    sm: "",
    md: "",
    lg: 32,
  },
  spacing: {
    lg: 0.5,
  },
  leading: {
    lg: 26,
  },
};
